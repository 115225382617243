<template>
    <NuxtLink :to="fullPath" class="author-article-card">
        <picture class="author-article-card__picture">
            <img :src="info.image?.url" alt="imagen de artículo" />
        </picture>

        <div class="author-article-card__info">
            <h2 class="author-article-card__info-title">
                {{ info.title }}
            </h2>

            <div class="author-article-card__info-description" v-html="truncatedContent"></div>
        </div>
    </NuxtLink>
</template>

<script lang="ts" setup>
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<Models.Article>,
        required: true,
    },
    category: {
        type: String,
    },
})

const content = computed(() => {
    return props.info.content?.replace(/<[^>]+>/g, '')
})

const truncatedContent = computed(() => {
    if (content.value) {
        const truncated = content.value?.slice(145)
        return truncated?.length < content.value?.length ? truncated + '...' : truncated
    } else return ''
})

const fullPath = computed(() => {
    return `/blog/${props.category}/${props.info.slug}`
})
</script>

<style lang="postcss">
.author-article-card {
    @apply flex w-[347px] flex-col rounded border border-gray-300 bg-white lg:w-full lg:flex-row lg:gap-x-[25px] lg:border-none lg:bg-transparent;

    &__picture {
        @apply flex h-[220px] w-full overflow-hidden rounded-t lg:h-[134px] lg:w-[180px] lg:flex-[0_0_180px] lg:rounded;
        img {
            @apply h-full w-full object-cover lg:rounded;
        }
    }

    &__info {
        @apply flex flex-col gap-y-[15px] p-[25px] lg:justify-center lg:gap-y-0 lg:p-0;

        &-title {
            @apply text-2xl font-medium lg:text-[28px];
        }

        &-description {
            @apply line-clamp-3 text-base leading-tight tracking-tighter;
        }
    }
}
</style>
